import React, { useState } from 'react';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Button, FormGroup } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { MonteCarloData } from 'helpers/fakebackend_helper';
import { createMonteCarlo } from 'slices/thunks';

interface ModalProps {
  show: boolean;
  handleClose: () => void;
}

const AddMonteCarloTest = ({ show, handleClose }: ModalProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [simulationType, setSimulationType] = useState('robo');
  const [simulationTypeError, setSimulationTypeError] = useState<string | null>(null);

  const dispatch: any = useDispatch();

  const validation = useFormik({
    initialValues: {
      nome_simulacao: '',
      magic_number: '',
      excelFiles: [],
      simulationType: 'robo',
    },
    validationSchema: Yup.object({
      nome_simulacao: Yup.string().required('Por favor, digite o nome da simulação'),
      magic_number: Yup.number().required('Por favor, digite o magic number').typeError('O magic number deve ser um número'),
      excelFiles: Yup.mixed().required('Por favor, faça o upload de arquivos CSV'),
      simulationType: Yup.string().required('Por favor, selecione um tipo de simulação'),
    }),
    onSubmit: async (values: any) => {
      if (simulationTypeError) {
        alert('Por favor, corrija os erros antes de continuar.');
        return;
      }

      // Preparar os dados para envio
      const monteCarloData: MonteCarloData = {
        nome_simulacao: values.nome_simulacao,
        num_simulacoes: parseInt(values.magic_number),
        tipo_simulacao: simulationType === 'robo' ? 1 : 2, // Aqui você define se é 'robo' ou 'portfolio'
        files: selectedFiles.map((file) => file), // Certifique-se de que esta é a estrutura correta para os arquivos
      };

      try {
        // Chamar a API de criação da simulação
        dispatch(createMonteCarlo(monteCarloData));

        // Após a submissão, resetar o formulário e fechar o modal
        validation.resetForm();
        setSelectedFiles([]);
        setSimulationType('robo');
        setSimulationTypeError(null);
        handleClose();
      } catch (error: any) {
        alert('Erro ao criar a simulação Monte Carlo: ' + error.message);
      }
    },
  });

  const handleFileChange = (event: any) => {
    const files: any = Array.from(event.target.files);
    setSelectedFiles(files);
    validation.setFieldValue('excelFiles', files.map((file: any) => file.name).join(', '));

    if (files.length > 1) {
      setSimulationType('portfolio');
      validation.setFieldValue('simulationType', 'portfolio');
      setSimulationTypeError(null);
    } else {
      setSimulationTypeError(null);
    }
  };

  const handleSimulationTypeChange = (event: any) => {
    const selectedType = event.target.value;
    if (selectedFiles.length > 1 && selectedType === 'robo') {
      setSimulationTypeError('Ao selecionar múltiplos arquivos, o tipo de simulação deve ser "Portfolio".');
    } else {
      setSimulationType(selectedType);
      validation.setFieldValue('simulationType', selectedType);
      setSimulationTypeError(null);
    }
  };

  const handleModalClose = () => {
    validation.resetForm();
    setSelectedFiles([]);
    setSimulationType('robo');
    setSimulationTypeError(null);
    handleClose();
  };

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={show} toggle={handleModalClose} centered>
        <ModalHeader className="bg-light p-3" toggle={handleModalClose}>
          Adicionar novo Monte Carlo
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e: any) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <ModalBody>
            <div className="mb-3">
              <Label htmlFor="nome_simulacao" className="form-label">
                Nome da Simulação Monte Carlo
              </Label>
              <Input
                name="nome_simulacao"
                id="nome_simulacao"
                className="form-control"
                placeholder="Digite o nome da simulação"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.nome_simulacao || ''}
                invalid={validation.touched.nome_simulacao && validation.errors.nome_simulacao ? true : false}
              />
              {validation.touched.nome_simulacao && validation.errors.nome_simulacao ? (
                <FormFeedback type="invalid" className="d-block">
                  {typeof validation.errors.nome_simulacao === 'string' ? validation.errors.nome_simulacao : 'Erro de validação'}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label htmlFor="magic_number" className="form-label">
                Magic Number
              </Label>
              <Input
                name="magic_number"
                id="magic_number"
                className="form-control"
                placeholder="Digite o magic number"
                type="number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.magic_number || ''}
                invalid={validation.touched.magic_number && validation.errors.magic_number ? true : false}
              />
              {validation.touched.magic_number && validation.errors.magic_number ? (
                <FormFeedback type="invalid" className="d-block">
                  {typeof validation.errors.magic_number === 'string' ? validation.errors.magic_number : 'Erro de validação'}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Tipo de Simulação</Label>
              <div className="d-flex">
                <FormGroup check className="me-4">
                  <Label check>
                    <Input type="radio" name="simulationType" value="robo" checked={simulationType === 'robo'} onChange={handleSimulationTypeChange} disabled={selectedFiles.length > 1} />
                    Robo
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="simulationType" value="portfolio" checked={simulationType === 'portfolio'} onChange={handleSimulationTypeChange} />
                    Portfolio
                  </Label>
                </FormGroup>
              </div>
              {simulationTypeError && (
                <FormFeedback type="invalid" className="d-block">
                  {simulationTypeError}
                </FormFeedback>
              )}
            </div>

            <div className="mb-3">
              <Label htmlFor="excelFiles" className="form-label">
                Upload Arquivos CSV
              </Label>
              <small className="text-muted d-block mb-2">Você pode fazer upload de vários arquivos CSV para simular múltiplas simulações ao mesmo tempo.</small>
              <Input
                name="excelFiles"
                id="excelFiles"
                className="form-control"
                type="file"
                accept=".csv"
                multiple
                onChange={handleFileChange}
                onBlur={validation.handleBlur}
                invalid={validation.touched.excelFiles && validation.errors.excelFiles ? true : false}
              />
              {validation.touched.excelFiles && validation.errors.excelFiles ? (
                <FormFeedback type="invalid" className="d-block">
                  {typeof validation.errors.excelFiles === 'string' ? validation.errors.excelFiles : 'Erro de validação'}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <div className="modal-footer">
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={handleModalClose}>
                Cancelar
              </Button>
              <Button type="submit" className="btn btn-success">
                Cadastrar
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AddMonteCarloTest;
