import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getListMonteCarlo, getListPortfolioMonteCarlo, getMonteCarloById, createMonteCarlo } from './thunk';

export type MonteCarloType = {
  id: number;
  magic_number: number;
  min_drawdown: number;
  medio_drawdown: number;
  max_drawdown: number;
  min_estagnacao_dia: number;
  medio_estagnacao_dia: number;
  max_estagnacao_dia: number;
  desvio_padrao_drawdown: number;
  numero_vezes_desvio_padrao_drawdown: number;
  dois_desvio_padrao_drawdown: number;
  numero_vezes_dois_desvio_padrao_drawdown: number;
  tres_desvio_padrao_drawdown: number;
  numero_vezes_tres_desvio_padrao_drawdown: number;
  maior_ganho_diario: number;
  maior_perda_diario: number;
  media_lucro_total: number;
  melhor_mes: number;
  media_mes: number;
  pior_mes: number;
  numero_simulacoes: number;
  risco_ruina: number;
  gerenciamento_risco: number;
  meses_negativo: number;
  trimestre_negativo: number;
  semestre_negativo: number;
  ano_negativo: number;
  imagem_monte_carlo: Buffer;
  user_id: number;
  user: any;
};

export type MonteCarloState = {
  listMonteCarloData: MonteCarloType[];
  listPortfolioMonteCarloData: MonteCarloType[];
  monteCarlo: MonteCarloType | null;
  error: string | null;
};

export const initialState: MonteCarloState = {
  listMonteCarloData: [],
  listPortfolioMonteCarloData: [],
  monteCarlo: null,
  error: null,
};

const MonteCarloSlice = createSlice({
  name: 'MonteCarlo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListMonteCarlo.fulfilled, (state, action: PayloadAction<MonteCarloType[]>) => {
      state.listMonteCarloData = action.payload;
    });
    builder.addCase(getListMonteCarlo.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to fetch listMonteCarloData';
    });
    builder.addCase(getListPortfolioMonteCarlo.fulfilled, (state, action: PayloadAction<MonteCarloType[]>) => {
      state.listPortfolioMonteCarloData = action.payload;
    });
    builder.addCase(getListPortfolioMonteCarlo.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to fetch listPortfolioMonteCarloData';
    });
    builder.addCase(getMonteCarloById.fulfilled, (state, action: PayloadAction<MonteCarloType>) => {
      state.monteCarlo = action.payload;
    });
    builder.addCase(getMonteCarloById.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to fetch monteCarlo by magic number';
    });
    builder.addCase(createMonteCarlo.fulfilled, (state, action: any) => {
      state.monteCarlo = action.payload;
    });
    builder.addCase(createMonteCarlo.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to create monteCarlo';
    });
  },
});

export default MonteCarloSlice.reducer;
