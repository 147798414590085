import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListMonteCarloByUserId, getListPortfolioMonteCarloByUserId, getMonteCarloByIdAndUserId, createMonteCarloData, MonteCarloData } from '../../helpers/fakebackend_helper';

export const getListMonteCarlo = createAsyncThunk('monteCarlo/getListMonteCarlo', async () => {
  try {
    return await getListMonteCarloByUserId();
  } catch (error) {
    throw error;
  }
});

export const getListPortfolioMonteCarlo = createAsyncThunk('monteCarlo/getListPortfolioMonteCarlo', async () => {
  try {
    return await getListPortfolioMonteCarloByUserId();
  } catch (error) {
    throw error;
  }
});

export const getMonteCarloById = createAsyncThunk('monteCarlo/getMonteCarloById', async ({ id }: { id: number }) => {
  try {
    return await getMonteCarloByIdAndUserId(id);
  } catch (error) {
    throw error;
  }
});

export const createMonteCarlo = createAsyncThunk('monteCarlo/createMonteCarlo', async (monteCarlo: MonteCarloData): Promise<any> => {
  try {
    return await createMonteCarloData(monteCarlo);
  } catch (error) {
    throw error;
  }
});
